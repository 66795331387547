<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-15 09:18:02
 * @LastEditTime: 2021-05-13 22:15:56
 * @FilePath: /shop_frontend/src/views/permissionsConfig/roleManage.vue
-->
<template>
  <div>
    <div class="page__btns">
      <a-button type="primary" @click="openAdd"> 添加权限组 </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
      bordered
      @change="changeTable"
    >
      <span slot="action" slot-scope="text, record">
        <div class="action_custom">
          <a-icon type="edit" @click="edit(record)" />
          <a-icon type="delete" @click="deleteHandle(record)" />
        </div>
      </span>
    </a-table>
    <a-modal
      title="权限组"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="700"
    >
      <div>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item ref="name" label="名称" prop="name">
            <a-input
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item label="权限">
            <div style="margin-bottom: 4px">
              <a-checkbox
                :indeterminate="indeterminate"
                :checked="checkAll"
                @change="onCheckAllChange"
              >
                全选
              </a-checkbox>
            </div>
            <div>
              <a-row
                class="border__row"
                v-for="el in permissionsTypes"
                :key="el.id"
              >
                <a-col span="8" class="border">
                  <a-checkbox
                    :checked="el.checked"
                    :indeterminate="el.indeterminate"
                    @change="changeAline(el, $event)"
                  >
                    {{ el.name }}
                  </a-checkbox>
                </a-col>
                <a-col span="16" class="border">
                  <a-checkbox-group
                    v-model="el.checkedList"
                    @change="onChange(el)"
                    style="width: 100%; height: 100%"
                  >
                    <a-checkbox
                      :value="item.id"
                      v-for="item in el.permissions"
                      :key="item.code + item.id"
                    >
                      {{ item.name }}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-col>
              </a-row>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "角色名称",
    dataIndex: "name",
    key: "name",
    align: 'center'
  },
  // {
  //   title: "创建时间",
  //   dataIndex: "address",
  //   key: "address",
  // },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: 'center'
  },
];
export default {
  name: "GoodsCategory",
  data() {
    return {
      checkedList: [],
      checkAll: false,
      indeterminate: false,
      dataSource: [],
      columns,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      visible: false,
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      // permissions: [],
      permissionsTypes: [],
    };
  },
  created() {
    this.initTable();
    // this.getPermissions();
    this.getPermissionsTypes();
  },
  computed: {
    params() {
      return { page: this.page };
    },
  },
  methods: {
    onChange(obj) {
      obj.indeterminate =
        !!obj.checkedList.length &&
        obj.checkedList.length < obj.permissions.length;
      obj.checked = obj.checkedList.length === obj.permissions.length;
      let t1 = this.permissionsTypes.reduce((total, el) => {
        return (total += el.permissions.length);
      }, 0);
      let t2 = this.permissionsTypes.reduce((total, el) => {
        return (total += el.checkedList.length);
      }, 0);
      this.checkAll = t1 === t2;
      this.indeterminate = !!t2 && t2 < t1;
      // this.indeterminate =
      //   !!checkedList.length && checkedList.length < this.permissions.length;
      // this.checkAll = checkedList.length === this.permissions.length;
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked;
      let _this = this;
      this.permissionsTypes.forEach((el) => {
        _this.$set(el, "checked", e.target.checked);
        let list = e.target.checked ? el.permissions.map((m) => m.id) : [];
        _this.$set(el, "checkedList", list);
      });
      this.indeterminate = false;
    },
    changeAline(obj, e) {
      console.log(obj, e);
      this.$set(obj, "checked", e.target.checked);
      this.$set(obj, "indeterminate", false);
      this.$set(
        obj,
        "checkedList",
        e.target.checked ? obj.permissions.map((el) => el.id) : []
      );
      let t1 = this.permissionsTypes.reduce((total, el) => {
        return (total += el.permissions.length);
      }, 0);
      let t2 = this.permissionsTypes.reduce((total, el) => {
        return (total += el.checkedList.length);
      }, 0);
      this.checkAll = t1 === t2;
      this.indeterminate = !!t2 && t2 < t1;
    },
    // getPermissions() {
    //   this.$axios.get("/permissions/").then((res) => {
    //     console.log(res);
    //     this.permissions = res;
    //   });
    // },
    getPermissionsTypes() {
      this.$axios.get("/permission_types/").then((res) => {
        console.log(res);
        this.permissionsTypes = res;
      });
    },
    openAdd() {
      this.visible = true;
      this.form = {
        name: "",
      };
      this.checkedList = [];
      this.checkAll = false;
      this.indeterminate = false;
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      // console.log(this.checkedList);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let list = [];
          this.permissionsTypes.forEach((el) => {
            list = [...list, ...el.checkedList];
          });
          this.form.permissions = list;
          let request = this.form.id
            ? this.$axios.put(`/groups/${this.form.id}/`, this.form)
            : this.$axios.post("/groups/", this.form);
          request.then((res) => {
            console.log(res);
            this.visible = false;
            this.$message.success("操作成功");
            this.initTable();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/groups/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    edit(record) {
      console.log(record);
      this.visible = true;
      let _this = this;
      let t1 = this.permissionsTypes.reduce((total, el) => {
        return (total += el.permissions.length);
      }, 0);
      this.$axios.get(`/groups/${record.id}/`).then((res) => {
        console.log(res);
        this.form = res;
        let list = res.permissions;
        this.checkAll = list.length === t1;
        this.indeterminate = !!list.length && list.length < t1;
        this.permissionsTypes.forEach((el) => {
          let arr = [];
          el.permissions.forEach((per) => {
            list.forEach((m) => {
              if (per.id === m) {
                arr.push(m);
              }
            });
          });
          _this.$set(el, "checkedList", arr);
          _this.$set(el, "indeterminate", !!arr.length && arr.length < el.permissions.length);
          _this.$set(el, "checked", arr.length === el.permissions.length);
        });
        console.log(this.permissionsTypes, '---')
      });
    },
    deleteHandle(record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/groups/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.border {
  // border: 1px solid #000;
}
.border__row {
  border: 1px solid #ddd;
  padding: 10px;
  &:last-of-type {
    border-top: none;
  }
  &:first-of-type {
    border-top: 1px solid #ddd;
  }
}
</style>